<template>
  <WsUpdate
    :modelName="modelName"
    :id="$route.params.id"
    :label="label"
    :fields="fields"
    :primary="_primary"
    v-model="state"
    :emitInput="true"
  >
  </WsUpdate>
</template>

<script>
import model from "@/__vue2stone_cms/models/service_plan_item";
export default {
  metaInfo() {
    return {
      title: `${this.$t("編輯")} ${this.$t(model.label)}`,
    };
  },
  data() {
    return {
      state: null,
      ...model,
    };
  },
  computed: {
    _primary() {
      const fields = ["name", "type"];
      if (this.state && this.state.type) {
        const type = this.state.type;
        if (type == "custom-items") {
          fields.push("items");
        }
        if (type == "count") {
          fields.push("unit");
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: fields,
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>